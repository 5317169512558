import React, { useState } from 'react';

const DesignBar = ({ setDesign }) => {
  const [borderRadius, setBorderRadius] = useState(0);
  const [fontFamily, setFontFamily] = useState('Arial');
  const [margin, setMargin] = useState(0);
  const [padding, setPadding] = useState(0);

  const handleBorderRadiusChange = (e) => {
    setBorderRadius(e.target.value);
    setDesign({ borderRadius, fontFamily, margin, padding });
  };

  const handleFontFamilyChange = (e) => {
    setFontFamily(e.target.value);
    setDesign({ borderRadius, fontFamily, margin, padding });
  };

  const handleMarginChange = (e) => {
    setMargin(e.target.value);
    setDesign({ borderRadius, fontFamily, margin, padding });
  };

  const handlePaddingChange = (e) => {
    setPadding(e.target.value);
    setDesign({ borderRadius, fontFamily, margin, padding });
  };

  return (
    <div style={{ padding: 20 }}>
      <div>
        <label>Border Radius: </label>
        <input
          type="range"
          min="0"
          max="50"
          value={borderRadius}
          onChange={handleBorderRadiusChange}
        />
      </div>
      <div>
        <label>Font: </label>
        <select value={fontFamily} onChange={handleFontFamilyChange}>
          <option value="Arial">Arial</option>
          <option value="Verdana">Verdana</option>
          <option value="Georgia">Georgia</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Courier New">Courier New</option>
        </select>
      </div>
      <div>
        <label>Margin: </label>
        <input
          type="range"
          min="0"
          max="50"
          value={margin}
          onChange={handleMarginChange}
        />
      </div>
      <div>
        <label>Padding: </label>
        <input
          type="range"
          min="0"
          max="50"
          value={padding}
          onChange={handlePaddingChange}
        />
      </div>
    </div>
  );
};

export default DesignBar;
