import React, { useState } from 'react';

const JsonInput = ({ setJson }) => {
  const [input, setInput] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
    try {
      const parsedJson = JSON.parse(e.target.value);
      setJson(parsedJson);
    } catch (err) {
      setJson(null);
    }
  };

  return (
    <textarea
      style={{ width: '100%', height: '100%' }}
      value={input}
      onChange={handleInputChange}
      placeholder="Paste JSON here..."
    />
  );
};

export default JsonInput;
