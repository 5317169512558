import React from 'react';
import ImageComponent from './ImageComponent';
import YoutubeComponent from './YoutubeComponent';

const JsonComponent = ({ data, design }) => {
  const isImageUrl = (url) => {
    return ((url.match(/^(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)(\?.*)?$/i) !== null) || (url.match(/unsplash\.com/i) !== null) );
  };


  const isYoutubeUrl = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?/;
    return url.match(regex) !== null;
  };

  const getYoutubeVideoId = (url) => {
    const regex = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
    const match = url.match(regex);
    return match && match[1].length === 11 ? match[1] : null;
  };

  const renderValue = (key, value) => {
    const style = {
      borderRadius: `${design.borderRadius}px`,
      fontFamily: design.fontFamily,
      margin: `${design.margin}px`,
      padding: `${design.padding}px`,
    };

    if (typeof value === 'string' && isImageUrl(value)) {
      return (
        <div key={key}>
          <ImageComponent src={value} alt={key} style={style}/>
        </div>
      );
    } else if (typeof value === 'string' && isYoutubeUrl(value)) {
      return (
        <div key={key}>
          <YoutubeComponent videoId={getYoutubeVideoId(value)} />
        </div>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <div key={key} style={style}>
          <JsonComponent data={value} design={design} />
        </div>
      );
    } else {
      return (
        <div key={key} style={style}>
          {value}
        </div>
      );
    }
  };

  return (
    <div>
      {Object.entries(data).map(([key, value]) => renderValue(key, value))}
    </div>
  );
};

export default JsonComponent;
