import React from 'react';
import JsonComponent from './JsonComponent';

const JsonRenderer = ({ json, design }) => {
  return (
    <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
      {json && <JsonComponent data={json} design={design} />}
    </div>
  );
};

export default JsonRenderer;
