import React, { useState } from 'react';
import JsonInput from './components/JsonInput';
import JsonRenderer from './components/JsonRenderer';
import DesignBar from './components/DesignBar';

function App() {
  const [json, setJson] = useState(null);
  const [design, setDesign] = useState({
    borderRadius: 0,
    fontFamily: 'Arial',
    margin: 20,
    padding: 0,
  });

  return (
    <div className="App" style={{ display: 'flex', height: '100vh' }}>
      <div style={{ width: '20%', borderRight: '1px solid #ccc' }}>
        <DesignBar setDesign={setDesign} />
      </div>
      <div style={{ width: '30%', borderRight: '1px solid #ccc' }}>
        <JsonInput setJson={setJson} />
      </div>
      <div style={{ width: '50%' }}>
        <JsonRenderer json={json} design={design} />
      </div>
    </div>
  );
}

export default App;

